













import { Observer } from 'mobx-vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class ClaimButton extends Vue {
  @Prop({ default: null }) item
  @Prop({ default: false }) isSelling?: boolean
}
